export const protectedResourceMap: [string, string[]][] = [
 
    ['https://buildtodoservice.azurewebsites.net/api/todolist',
   
     ["https://triplinkqa.onmicrosoft.com/triplinkapiqa/read"]],
   
     ['https://graph.microsoft.com/v1.0/me', ["https://triplinkqa.onmicrosoft.com/triplinkapiqa/read"]]
   
    ];
   
    export const environment = {
   
      production: false,
   
      hmr: false,
   
      tripLinkConfig: {
   
       tenant: "triplinkqa.onmicrosoft.com",
   
       clientId: "f95eb1aa-b8a6-4b4b-9f69-beda359b095f",
   
       usersApi: 'https://triplinkusermgmtapiqa.azurewebsites.net/',
   
       genSetApi:'https://triplinkgensetapiqa.azurewebsites.net',
   
       assetApi: 'https://triplinkreeferapiqa.azurewebsites.net',
   
       filtersApi: 'https://triplinkfilterapiqa.azurewebsites.net',
   
       authority: "https://triplinkqa.b2clogin.com/tfp/triplinkqa.onmicrosoft.com/B2C_1_triplinkqasignupin",
   
       forgotPasswordAuthority: "https://triplinkqa.b2clogin.com/tfp/triplinkqa.onmicrosoft.com/B2C_1_triplinkqaresetpwd",    
   
       forgotPasswordScope: "B2C_1_triplinkqaresetpwd",
   
       redirectUri: "https://qa.container.lynxfleet.carrier.com/",
   
       cacheLocation: "localStorage",
   
       postLogoutRedirectUri: "https://qa.container.lynxfleet.carrier.com/",
   
       GoogleMapapiKey: "AIzaSyBcw5Fy57o_5V92xHiGW_eR1pCiFDKXeIA",
   
       validateAuthority: false,
   
       navigateToLoginRequestUrl: true,
   
       popUp: false,
   
       extraQueryParameters : "",
   
       consentScopes: ["https://triplinkqa.onmicrosoft.com/triplinkapiqa/read"],
   
       unprotectedResources: ["https://www.microsoft.com/en-us/"],
   
       correlationId: "1234",
   
       piiLoggingEnabled: true,

        storeAuthStateInCookie: false, // dynamically set to true when IE11
        loadFrameTimeout : 6,
        dashboardAPI: 'https://triplinkuiapiqa.azurewebsites.net',
        versionNumber : "v 7.3.5",
   
       version: "0.0.0"
   
      },
   
      appInsights: {
   
       // instrumentationKey : '20bffbf9-b367-4f46-be4c-b5584e40f5ab'
   
       instrumentationKey : '0fe0067b-b3c0-465a-988c-d9904256b48e'
   
   
   
   
      }
   
     };